exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-games-games-json-parent-file-name-tsx": () => import("./../../../src/pages/games/{GamesJSON.parent__(File)__name}.tsx" /* webpackChunkName: "component---src-pages-games-games-json-parent-file-name-tsx" */),
  "component---src-pages-games-index-tsx": () => import("./../../../src/pages/games/index.tsx" /* webpackChunkName: "component---src-pages-games-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-remark-parent-file-source-instance-name-index-tsx": () => import("./../../../src/pages/{MarkdownRemark.parent__(File)__sourceInstanceName}/index.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-parent-file-source-instance-name-index-tsx" */),
  "component---src-pages-markdown-remark-parent-file-source-instance-name-markdown-remark-parent-file-name-tsx": () => import("./../../../src/pages/{MarkdownRemark.parent__(File)__sourceInstanceName}/{MarkdownRemark.parent__(File)__name}.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-parent-file-source-instance-name-markdown-remark-parent-file-name-tsx" */),
  "component---src-pages-others-tsx": () => import("./../../../src/pages/others.tsx" /* webpackChunkName: "component---src-pages-others-tsx" */),
  "component---src-pages-policies-ishiatamachan-tsx": () => import("./../../../src/pages/policies/ishiatamachan.tsx" /* webpackChunkName: "component---src-pages-policies-ishiatamachan-tsx" */),
  "component---src-pages-relatedsites-tsx": () => import("./../../../src/pages/relatedsites.tsx" /* webpackChunkName: "component---src-pages-relatedsites-tsx" */)
}

